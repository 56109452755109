import React, {
   ReactNode,
   createContext,
   useContext,
   useEffect,
   useState,
   useRef,
} from 'react';
import Keycloak from 'keycloak-js';
import axios, { HttpStatusCode } from 'axios';

const w: any = window;
const kcUrl = w?._env_?.KC_URL;
const kcRealm = w?._env_?.KC_REALM;
const kcClientId = w?._env_?.KC_CLIENT_ID;
const kcEnabled = w?._env_?.KC_ENABLED;
const environment = w?._env_?.ENVIRONMENT ?? 'LOCAL';

let keycloak: any = new Keycloak({
   url: kcUrl,
   realm: kcRealm,
   clientId: kcClientId,
});

export default keycloak;

function getLocalImpersonatorData() {
   const kcTokenData = localStorage.getItem('kcToken');
   if (kcTokenData) {
      return JSON.parse(kcTokenData);
   }
   return null;
}

function setLocalImpersonatorData(token: string, refreshToken: string, roles: string[]) {
   const tokenData = { token, refreshToken, roles };
   localStorage.setItem('kcToken', JSON.stringify(tokenData));
}

function removeLocalImpersonatorData() {
   localStorage.removeItem('kcToken');
}

function setKeycloakInstanceData(token: string, refreshToken: string, roles: string[]) {
   keycloak.token = token;
   keycloak.authenticated = true;
   keycloak.refreshToken = refreshToken;
   keycloak.realmAccess = { roles };
}

async function refreshToken() {
   try {
      const kcTokenData = getLocalImpersonatorData();
      if (kcTokenData?.refreshToken) {
         const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: {
               client_id: kcClientId,
               grant_type: 'refresh_token',
               refresh_token: kcTokenData.refreshToken,
            },
            url: `${kcUrl}/realms/${kcRealm}/protocol/openid-connect/token`,
         };
         const response = await axios(options);
         if (response.status === HttpStatusCode.Ok) {
            kcTokenData.token = response.data.access_token;
            kcTokenData.refreshToken = response.data.refresh_token;
            localStorage.setItem('kcToken', JSON.stringify(kcTokenData));
         }
      }
   } catch {
      console.warn(
         `Error while exchanging refresh token for an access token`
      );
      return false;
   }
}

export async function initKeycloak(
   onInitCallback: (authenticated: boolean) => void
) {
   if (!kcEnabled) {
      onInitCallback(false);
      return;
   }

   if (keycloak.authenticated !== undefined) {
      onInitCallback(!!keycloak.authenticated);
      return;
   }

   try {
      const redirectUri = window.location.origin + window.location.pathname;
      const enableLogging = environment === 'LOCAL';

      const authenticated = await keycloak.init({
         onLoad: 'check-sso',
         checkLoginIframe: false,

         // These fields weren't originally present in this file. They've been imported from 'keyCloakProvider'.
         // They weren't being used till now, add them back if the need arises [Hashim, 20feb2025]
         // pkceMethod: 'S256',
         // redirectUri: redirectUri,
         // enableLogging: enableLogging,
         // scope: 'profile impersonator oid email',
      });
      onInitCallback(authenticated);
   } catch (error) {
      console.error('Keycloak initialization error:', error);
      onInitCallback(false);
   }
}

interface KeycloakContextProps {
   keycloakAuth: boolean;
   exchangeToken: (token: string, refreshToken: string, roles: string[]) => void;
   handleBackToImpersonator: () => void;
}

const KeycloakContext = createContext<KeycloakContextProps | undefined>(
   undefined
);

interface KeycloakProviderProps {
   children: ReactNode;
}

export const KeycloakProvider: React.FC<KeycloakProviderProps> = ({
   children,
}) => {
   const [keycloakAuth, setKeycloakAuth] = useState<boolean>(false);
   const [tokenExchange, setTokenExchange] = useState<boolean>(false);
   const exchangeRefreshIntervalId = useRef<NodeJS.Timer | undefined>(undefined);

   // Perform auto-init here if you want the provider to do it automatically.
   // Alternatively, you can rely on App.tsx calling initKeycloak() manually.
   useEffect(() => {
      if (!kcEnabled) return; // no Keycloak
      // If there's local impersonator data, we might use it
      if (getLocalImpersonatorData()) {
         setTokenExchange(true);
      }

      // Example Keycloak event: refresh the token if it’s about to expire
      keycloak.onTokenExpired = () => {
         keycloak
            .updateToken()
            .catch((error: any) => {
               reset();
               console.error('Error while refreshing Keycloak Token: ', error);
               window.location.href = '/login';
            });
      };

      // Optionally call our initKeycloak from here (without callback)
      initKeycloak((authenticated) => {
         setKeycloakAuth(authenticated);
      }).catch(() => {
         reset();
      });
   }, []);

   // If we’re currently exchanging tokens (impersonation flow),
   // refresh that token every 60s:
   useEffect(() => {
      if (tokenExchange) {
         exchangeRefreshIntervalId.current = setInterval(refreshToken, 60000);
      }
      return () => {
         if (exchangeRefreshIntervalId.current) {
            clearInterval(exchangeRefreshIntervalId.current);
            exchangeRefreshIntervalId.current = undefined;
         }
      };
   }, [tokenExchange]);

   // Let child components (like a special "login-as" feature) swap tokens
   const exchangeToken = (
      token: string,
      refreshToken: string,
      roles: string[]
   ) => {
      if (keycloak.refreshToken && keycloak.token) {
         // Save the old one for "back to impersonator" usage
         setLocalImpersonatorData(
            keycloak.token,
            keycloak.refreshToken,
            keycloak.realmAccess?.roles ?? []
         );
         setTokenExchange(true);
      }
      // Now set the new token on the keycloak instance
      setKeycloakInstanceData(token, refreshToken, roles);
      setKeycloakAuth(true);
   };

   // "Back to impersonator" means revert the token to the original
   const handleBackToImpersonator = () => {
      const impersonatorToken = getLocalImpersonatorData();
      if (impersonatorToken) {
         setKeycloakInstanceData(
            impersonatorToken.token,
            impersonatorToken.refreshToken,
            impersonatorToken.roles
         );
         removeLocalImpersonatorData();
         setTokenExchange(false);
         setKeycloakAuth(true);
      } else {
         keycloak.logout().then(() => {
            reset();
         });
      }
   };

   // Clear everything
   const reset = () => {
      localStorage.clear();
      keycloak.clearToken();
      setKeycloakAuth(false);
   };

   const contextValue: KeycloakContextProps = {
      keycloakAuth,
      exchangeToken,
      handleBackToImpersonator,
   };

   return <KeycloakContext.Provider value={contextValue}> {children} </KeycloakContext.Provider>;
};

export const useKeycloak = () => {
   const context = useContext(KeycloakContext);
   if (!context) {
      throw new Error('useKeycloak must be used within a KeycloakProvider');
   }
   return context;
}
